import { Link } from "gatsby"
import React, { FC } from "react"
import styled from "styled-components"

interface OfferButtonProps {}

const StyledLink = styled(Link)`
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0;
  font-weight: bold;
`

export const OfferButton: FC<OfferButtonProps> = () => {
  return (
    <>
      <StyledLink to="/oferta">Sprawdź więcej w naszej ofercie!</StyledLink>
    </>
  )
}

export default OfferButton
